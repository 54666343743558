import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  name: '',
  company:'',
  profile:'',
  created:'',
  evalPeriod:0,
  isLoggedIn: false,
  features: '',
  login: (token,company,expirationTime,name,profile,created, evalPeriod, features) => {},
  logout: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedCompany = localStorage.getItem('company');
  const storedExpirationDate = localStorage.getItem('expirationTime');
  const storedName = localStorage.getItem('name');
  const storedProfile = localStorage.getItem('profile');
  const storedCreated = localStorage.getItem('created');
  const storedEvalPeriod = localStorage.getItem('evalPeriod');
  const storedFeatures = localStorage.getItem('features');
  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem('name')
    localStorage.removeItem('company')
    localStorage.removeItem('profile');
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('created');
    localStorage.removeItem('evalPeriod');
    localStorage.removeItem('features')
    return null;
  }

  return {
    name: storedName,
    company: storedCompany,
    profile: storedProfile,
    token: storedToken,
    duration: remainingTime,
    created: storedCreated,
    evalPeriod: storedEvalPeriod,
    features: storedFeatures
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  
  let initialToken;
  let name;
  let company;
  let profile;
  let created;
  let evalPeriod;
  let features;
  if (tokenData) {
    initialToken = tokenData.token;
    profile = tokenData.profile;
    company = tokenData.company;
    name = tokenData.name;
    created = tokenData.created;
    evalPeriod = tokenData.evalPeriod;
    features = tokenData.features;
  }

  const [token, setToken] = useState(initialToken);

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem('name')
    localStorage.removeItem('company');
    localStorage.removeItem('profile');
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('created');
    localStorage.removeItem('evalPeriod');
    localStorage.removeItem('features');

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, company, expirationTime,name,profile, created, evalPeriod, features) => {
    setToken(token);
    localStorage.setItem('name', name);
    localStorage.setItem('company', company);
    localStorage.setItem('profile', profile);
    localStorage.setItem('token', token);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('created', created);
    localStorage.setItem('evalPeriod', evalPeriod);
    localStorage.setItem('features', features);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      console.log(tokenData.duration);
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    comapny: company,
    name: name,
    profile:profile,
    created: created,
    evalPeriod: evalPeriod,
    isLoggedIn: userIsLoggedIn,
    features: features,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
