import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { TSetupProps } from "./Setup.types";
import { toast } from "react-toastify";
import Wizard from "../../common/Wizard/Wizard";
import { WizardNavigationTabStates } from "../../common/Wizard/WizardNavigation/WizardNavigation.types";
import { TWizardBodyType } from "../../common/Wizard/Wizard.types";
import checkedGreen from "../../../../assets/images/checked-green-fill.svg";
import playIcon from "../../../../assets/images/play-white.svg";
import TabView from "../../common/TabView/TabView";
import ConsoleWindow from "../../common/ConsoleWindow/ConsoleWindow";
import CodeEditor from "../../common/CodeEditor/CodeEditor";
import DataTable from "react-data-table-component";
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import zipIconWhite from "../../../../assets/images/zip.svg";
import downloadIcon from "../../../../assets/images/download_grey.svg";
import saveIcon from "../../../../assets/images/save.svg";
import deleteIconWhite from "../../../../assets/images/trash-white.svg";
import folder from "../../../../assets/images/folder.svg";
import folderOpen from "../../../../assets/images/folder-open.svg";
import LaunchingModal from "./LaunchingModal/LaunchingModal";
import DeleteModal from "../../common/DeleteModal/DeleteModal";
import AuthContext from "../../../../store/auth-context";

import sessionsApi from "../../../../services/sessions";
import dataSourcesApi from "../../../../services/data-sources";
import useApi from "../../../../services/Base/useApi";

import { useNavigate, useSearchParams } from "react-router-dom";
import { updateSourceFile } from "typescript";

import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { text } from "stream/consumers";

const Setup = (props: TSetupProps) => {
  //=====================================
  //STATE
  //======================================
  const authCtx = useContext(AuthContext);
  const features = JSON.parse(authCtx.features);
  const company = localStorage.getItem('company')
  const [deleteModal, setDeleteModal] = useState(false);
  const [launchingModal, setLaunchingModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState("");
  const [setupSessions, setSetupSessions] = useState([]);
  const [sessionData, setSessionData] = useState<any>({
    textCategories:{wakewords:[],objects:[],actions:[],slot1:[],slot2:[]},
    dakConfig:{"NC":"0","Mode":"NF","VAD":"0","split_ratio":"25"},
    tsdkConfig:{"epochs":"15","dropout":"30","batch_size":"64","l2_regularization":"0","patience":"4","training_seed1":"0","training_seed2":"0","training_seed3":"0"},
    isdkConfig:{"DE":"0","HR_SUPPRRES":"1","DETECTION_SUPPRESS":"1","ROC_SUPPRESS":"1"}
  });
  const [dataOptions, setDataOptions] = useState({
    INPUT: [],
    NOISE: [],
    NOISE_AMBIENT: [],
    NOISE_IMPULSIVE: [],
    FALSE_ALARM: [],
    HIT_RATE: [],
    WEIGHTS: [],
    DETECTIONS: [],
    DAK: [],
    TSDK: [],
    ISDK: [],
  });
  const [weights, setWeights] = useState([]);
  const [weight, setWeight] = useState({ folder: "", file: "" });
  const [epochWarning, setEpochWarning] = useState("");
  const [splitWarning, setSplitWarning] = useState("");
  const [classNames, setClassNames] = useState([])
  const [metaData, setMetaData] = useState<any>({class_names:[]})
  const [ttsInput, setTtsInput] = useState("")
  const [wakewordsInput, setWakewordsInput] = useState("")
  const [objectsInput, setObjectsInput] = useState("")
  const [actionsInput, setActionsInput] = useState("")
  const [slot1Input, setSlot1Input] = useState("")
  const [slot2Input, setSlot2Input] = useState("")


  //=====================================
  //HOOKS
  //======================================
  const getSessionDataApi = useApi(sessionsApi.getSession);
  const getSetupSessionsApi = useApi(sessionsApi.getSetupSessions);
  const getDataOptionsApi = useApi(dataSourcesApi.getDataSource);
  const saveSessionApi = useApi(sessionsApi.putSaveSession);
  const deleteSessionApi = useApi(sessionsApi.deleteSession);
  const startTrainingApi = useApi(sessionsApi.postStartSession);
  const getWeightsApi = useApi(dataSourcesApi.getWeights);

  const paths = useRef({
    rawDataPath: "",
    noiseDataPath: "",
    hitRateDataPath: "",
    falseAlarmDataPath: "",
    detectionsDataPath: ""
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (sessionId) {
      setLoading(true);
      getSessionDataApi.request(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    getSetupSessionsApi.request();
  }, []);

  useEffect(() => {
    //set data results
    if (getSessionDataApi.error) {
      toast.error("Session not found.");
      navigate("/admin/session/overview");
    } else if (
      getSessionDataApi.loading === false &&
      !getSessionDataApi.error &&
      getSessionDataApi.data
    ) {
      setData(getSessionDataApi.data);
      getDataOptionsApi.request(fetchAwsDirectoryPaths(getSessionDataApi.data["mode"]))
      //findActiveIndex(getSessionDataApi.data);
      setLaunchingModal(getSessionDataApi.data["status"] === 1 ? true : false);
      // paths.current={
      //   rawDataPath:getSessionDataApi['data']['rawDataPath'],
      //   noiseDataPath:getSessionDataApi['data']['noiseDataPath'],
      //   hitRateDataPath:getSessionDataApi['data']['hitRateDataPath'],
      //   falseAlarmDataPath:getSessionDataApi['data']['falseAlarmDataPath']
      // }
      setLoading(false);
    }
  }, [
    getSessionDataApi.loading,
    getSessionDataApi.error,
    getSessionDataApi.data,
  ]);

  useEffect(() => {
    //set setup sessions dropdown options
    if (
      getSetupSessionsApi.loading === false &&
      !getSetupSessionsApi.error &&
      getSetupSessionsApi.data
    ) {
      setSetupSessions(getSetupSessionsApi.data);
      if (searchParams.get("id")) {
        //@ts-ignore
        setSessionId(searchParams.get("id"));
      } else if (getSetupSessionsApi.data[0]) {
        setSessionId(getSetupSessionsApi.data[0]["_id"]);
        navigate(
          `/admin/session/setup?id=${getSetupSessionsApi.data[0]["_id"]}`
        );
      } else {
        setLoading(false);
      }
    }
  }, [
    getSetupSessionsApi.loading,
    getSetupSessionsApi.error,
    getSetupSessionsApi.data,
  ]);

  useEffect(() => {
    //set data options
    if (
      getDataOptionsApi.loading === false &&
      !getDataOptionsApi.error &&
      getDataOptionsApi.data
    ) {
      setDataOptions(getDataOptionsApi.data);
      sortTargetedData(getDataOptionsApi.data);
    }
  }, [
    getDataOptionsApi.loading,
    getDataOptionsApi.error,
    getDataOptionsApi.data,
  ]);

  useEffect(() => {
    //handle after start training
    if (startTrainingApi.loading === false) {
      if (startTrainingApi.error) {
        toast.error(
          "There was an error launching your session. Check instance limit."
        );
        setSessionData({ ...sessionData, status: 0});
        startTrainingApi.clearError();
      } else {
        if (startTrainingApi["data"] && startTrainingApi["data"]["message"]) {
          toast.success(startTrainingApi["data"]["message"]);
        }

        navigate("/admin/session/overview");
      }
    }
  }, [startTrainingApi.loading, startTrainingApi.error, startTrainingApi.data]);

  useEffect(() => {
    //handle saved session
    if (saveSessionApi.loading === false) {
      if (saveSessionApi.error) {
        toast.error(saveSessionApi.error);
        saveSessionApi.clearError();
      } else {
        toast.success("Session saved");
      }
    }
  }, [saveSessionApi.loading, saveSessionApi.error, saveSessionApi.data]);

  useEffect(() => {
    if (deleteSessionApi.loading === false) {
      if (deleteSessionApi.error) {
        toast.error(deleteSessionApi.error);
        deleteSessionApi.clearError();
      } else {
        toast.success("Session deleted");
        navigate("/admin/session/overview");
      }
    }
  }, [deleteSessionApi.loading, deleteSessionApi.error, deleteSessionApi.data]);

  useEffect(() => {
    if (
      getWeightsApi.loading === false &&
      !getWeightsApi.error &&
      getWeightsApi.data
    ) {
      setWeights(getWeightsApi.data);
    }
  }, [getWeightsApi.loading, getWeightsApi.error, getWeightsApi.data]);

  //=====================================
  //HANDLERS
  //======================================
  const fetchAwsDirectoryPaths = (mode: string): string =>{
    switch(mode){
      case 'Sensors':
        return 'aws_s3_directories_session_sensors'
      case 'Denoiser':
        return 'aws_s3_directories_session_denoiser'
      default:
        return 'aws_s3_directories_session'
    }
  }

  const setData = (data: any) => {
    let dakConfig =
      data.mode !== "Audio"
        ? { split_ratio: data.dakConfig.split_ratio }
        : { ...data.dakConfig }
    
    let tsdkConfig = 
      data.mode == "Denoiser"
        ? { ...data.tsdkConfig, lr: 0.02, batch_size: 256, epochs: 75}
        : { ...data.tsdkConfig }
    
    let isdkConfig = { ...data.isdkConfig };
    if (data.mode == 'Denoiser') {
      delete isdkConfig.aggressiveness;
    };




    setSessionData({ 
      ...data, 
      dakConfig, 
      isdkConfig, 
      ambientNoiseDataPaths:[], 
      impulsiveNoiseDataPaths: [], 
      tsdkConfig
    });
  };

  const sortTargetedData = (data: any) => {
    if (data.mode === "Denoiser"){
      data.NOISE_AMBIENT.map((a: any) => a['type'] = 'Ambient');
      data.NOISE_IMPULSIVE.map((a: any) => a['type'] = 'Impulsive');
    };
  }

  const findActiveIndex = (session: any) => {
    const { rawDataPath, noiseDataPath, hitRateDataPath, falseAlarmDataPath } =
      session;
    if (!rawDataPath && !noiseDataPath) {
      setActiveIndex(0);
    } else if (
      rawDataPath &&
      noiseDataPath &&
      !hitRateDataPath &&
      !falseAlarmDataPath
    ) {
      setActiveIndex(1);
    } else if (hitRateDataPath && falseAlarmDataPath) {
      setActiveIndex(3);
    } else if (hitRateDataPath || falseAlarmDataPath) {
      setActiveIndex(2);
    }
  };
  // services
  // get session info
  const getSession = (sessionId: string) => {
    getSessionDataApi.request(sessionId);
  };
  // get all sessions in Setup
  const getSetupSessions = () => {
    getSetupSessionsApi.request();
  };
  // get data options
  const getDataOptions = (directoryPaths: string) => {
    getDataOptionsApi.request(directoryPaths);
  };
  // save session
  const saveSession = () => {
    const { rawDataPath, noiseDataPath, hitRateDataPath, falseAlarmDataPath } =
      paths.current;
    const data = {
      ...sessionData,
      rawDataPath,
      noiseDataPath,
      hitRateDataPath,
      falseAlarmDataPath,
    };
    saveSessionApi.request(sessionId, data);
    setSessionData(data);
  };
  // delete session
  const deleteSession = () => {
    deleteSessionApi.request(sessionId);
  };
  //get weights
  const getWeights = (folderName: string) => {
    getWeightsApi.request(folderName, sessionData.mode);
  };

  const fetchClassMetaData = async() => {
    console.log(sessionData.tts)
    if (sessionData.tts){
      recommendedEpochs({})
    }else{
      setMetaData({class_names:[]})
      if (typeof process.env.REACT_APP_AWS_ACCESS_KEY_ID !== 'string' || typeof process.env.REACT_APP_AWS_SECRET_ACCESS_KEY !== 'string') {
        throw new Error("AWS credentials must be provided!");
      }
    
        const s3Client = new S3Client({ 
          region: 'us-east-2',
          credentials: {
            accessKeyId:process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
          },
        });
      
        const bucketName = "aoncluster-bucket";
        
        const { rawDataPath, mode } = sessionData;
        let key = ''
        if (mode == "Audio") {
          key = `${company}/Input/${rawDataPath}/metadata.json`;
        } else if (mode == "Sensor"){
          key = `${company}/Input_Sensors/${rawDataPath}/metadata.json`;
        }

        try{
          const command:any  = new GetObjectCommand({
            Bucket: bucketName,
            Key : key
          })

          const response:any = await s3Client.send(command);
          const bodyContent:any = await streamToString(response.Body)

          const metadata = JSON.parse(bodyContent);
          setMetaData(metadata)
          recommendedEpochs(metadata)
        }catch (err) {
          startSession();
        }
    }
    

  }

// Helper function to convert the readable stream to a string
const streamToString = async (stream: any) => {
  const reader = stream.getReader();
  const decoder = new TextDecoder("utf-8");
  let result = "";
  let done = false;

  while (!done) {
    const { value, done: readerDone } = await reader.read();
    done = readerDone;
    if (value) {
      result += decoder.decode(value, { stream: true });
    }
  }

  return result;
};

  //check recommended epochs
  const recommendedEpochs = (metadata: any) => {
    const { tsdkConfig } = sessionData;
    const { epochs } = tsdkConfig;
    console.log("EPOCHS")
    if (sessionData.tts){
      const numClasses = sessionData.textClasses.length;
      if ((numClasses == 1 || numClasses == 2) && epochs < 15) {
        setEpochWarning(`For optimal performance set epochs to at least 15.`);
      } else if ((numClasses >= 3) && epochs < 25) {
        setEpochWarning("For optimal performance set epochs to at least 25.");
      } else {
        startSession()
      }
    }else {
      const numClasses = metadata.class_names.length;
      if ((numClasses == 1 || numClasses == 2) && epochs < 15) {
        setEpochWarning(`For optimal performance set epochs to at least 15.`);
      } else if ((numClasses == 3 || numClasses == 4) && epochs < 20) {
        setEpochWarning("For optimal performance set epochs to at least 20.");
      } else if (numClasses >= 5 && numClasses <= 8 && epochs < 25) {
        setEpochWarning("For optimal performance set epochs to at least 25.");
      } else if (numClasses >= 9 && numClasses <= 14 && epochs < 30) {
        setEpochWarning("For optimal performance set epochs to at least 30.");
      } else if (numClasses >= 15 && numClasses <= 20 && epochs < 35) {
        setEpochWarning("For optimal performance set epochs to at least 35.");
      } else {
        recommendedSplitRatio(metadata);
      }
    }
  };

  const recommendedSplitRatio = (metadata : any) => {
    setEpochWarning("");
    const { dakConfig } = sessionData;
    const { split_ratio } = dakConfig;
    const numClasses = metadata.class_names.length;
    if (numClasses >= 1 && numClasses <= 5 && split_ratio != 25) {
      setSplitWarning(`For optimal performance set split ratio to 25.`);
    } else if (numClasses >= 6 && numClasses <= 10 && split_ratio != 15) {
      setSplitWarning(
        "For optimal performance set split ratio to at least 15."
      );
    } else if (numClasses >= 11 && split_ratio != 5) {
      setSplitWarning("For optimal performance set split ratio to at least 5.");
    } else {
      startSession();
    }
  };

  // start training
  const startSession = () => {
    setEpochWarning("");
    const ambientNoiseDataPaths = extractNames(sessionData.ambientNoiseDataPaths);
    const impulsiveNoiseDataPaths = extractNames(sessionData.impulsiveNoiseDataPaths);
    let data = { ...sessionData, ambientNoiseDataPaths, impulsiveNoiseDataPaths };
    if (sessionData.mode === "Denoiser") data.runIsdk = false;
    if (weight.folder && weight.file)
      data.weightsDataPath = { ...weight, initialize: true };
    
    startTrainingApi.request(sessionId, data);
    setSessionData({ ...data, status: 1 });
    setLaunchingModal(true);
  };

  const extractNames = (data: Array<any>): Array<string> => {
    let names: Array<string> = [];
    data.map((item: any) => names.push(item.name));
    return names;
  };
  //handle input change
  // const handleOnSelectedRowsChange = (selectedRows:Array<any> ,path: string) => {
  //   if(selectedRows.length > 0){
  //     //@ts-ignore
  //     paths.current[path] = selectedRows[0].name
  //   }else{
  //     //@ts-ignore
  //     paths.current[path] = ""
  //   }
  // }

  const handleOnSelectedRowsChange = (
    selectedRows: Array<any>,
    path: string,
  ) => {
    let updatedSession = { ...sessionData };
    if (selectedRows.length > 0) {
      updatedSession[path] = selectedRows[0].name;
      if(selectedRows[0].type){
        updatedSession["noiseDataTypePath"] = selectedRows[0].type;
      }
    } else {
      updatedSession[path] = "";
    }
    setSessionData(updatedSession);
  };

  //render table
  const sessionSetupTableDT = (data: Array<string>, path: string, single = true) => {
    //columns for tables
    const columnsForTable = [
      {
        name: "Name",
        selector: (row: any) => row.name,
      },
        ...(sessionData.mode == "Targeted_Denoiser" && path == "noiseDataPath"
        ? [
            {
              name: "Type",
              selector: (row: any) => row.type,
            },
          ]
        : []),
      {
        name: "Last Modified",
        selector: (row: any) => row.modified,
      },
    ];

    //pre select rows
    //const rowSelectCritera = (row:any) => row.name === sessionData[path]

    return (
      <div>
       
        {
          path == "rawDataPath" && ["Type2Speech","SpeechIntent"].includes(sessionData.modelType)
            ? textToSpeechInput()
            :<DataTable
              columns={columnsForTable}
              data={data}
              customStyles={DTStyles}
              selectableRows={true}
              selectableRowsSingle={single}
              selectableRowsHighlight
              onSelectedRowsChange={({ selectedRows }) =>{
                if(single){
                  handleOnSelectedRowsChange(selectedRows, path)
                }else{
                  setSessionData({ ...sessionData, [path]: selectedRows })
                }
              }}
            />
        }
        
      </div>
    );
  };

  const handleRemoveClass = (index: number) => {
    // Create a new array without the clicked item
    const updatedTextClasses = sessionData.textClasses.filter((_:string, idx:number) => idx !== index);
    // Update the state
    setSessionData({...sessionData, textClasses:updatedTextClasses});
  };

  const handleRemoveSpeechIntentClass = (index: number, category: string) => {
    let updatedTextCategories = {...sessionData.textCategories}
    let word = updatedTextCategories[category][index]
    updatedTextCategories[category].splice(index,1)
    let updatedTextClasses = [...sessionData.textClasses]
    let textClassesIndex = null
    updatedTextClasses.map((cls: string, idx: number) => {
      if (cls == word){
        textClassesIndex = idx
      }
    })
    if (textClassesIndex != null){
      updatedTextClasses.splice(textClassesIndex, 1)
    }
  
    setSessionData({...sessionData,textClasses: updatedTextClasses, textCategories: updatedTextCategories});
  };



  const textToSpeechInput = () => {
    if (sessionData.modelType == "SpeechIntent"){
      return (
        <div>
          {textToSpeechIntentInput("Wakewords (Select up to 4)", setWakewordsInput, wakewordsInput, 4, "wakewords")}
          {textToSpeechIntentInput("Objects (Select up to 10)", setObjectsInput, objectsInput, 10,"objects")}
          {textToSpeechIntentInput("Actions (Select up to 10)", setActionsInput, actionsInput, 10, "actions")}
          {textToSpeechIntentInput("Slot1 (Select up to 10)", setSlot1Input, slot1Input, 10, "slot1")}
          {textToSpeechIntentInput("Slot2 (Select up to 10)", setSlot2Input, slot2Input, 10, "slot2")}
         </div>
      )
    }else{
      return (
        <div>
           <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width:500 }}>
            <input
              type="text"
              value={ttsInput}
              onChange={(e) => setTtsInput(e.target.value)}
              className="borderInp"
            />
            <button onClick={addTtsText} className="btnYellow btnCustom modalButton">Add</button>
          </div>
          {sessionData.textClasses.map((cls: string, idx: number) => (
          <div key={idx} style={{ display: 'flex', alignItems: 'center', gap: '8px',marginBottom:10,marginTop:10 }}>
            <p style={{ margin: 0, fontWeight: 'bold' }}>{idx + 1}. {cls}</p>
            <button 
              onClick={() => handleRemoveClass(idx)}
              style={{ borderRadius:5, padding: '4px 8px', backgroundColor: 'red', color: 'white', border: 'none', cursor: 'pointer' }}>
              delete
            </button>
          </div>
        ))}
        </div>
      )
    }
  }

  const textToSpeechIntentInput = (label:string,setInput:any,input:string,max:number,category:string) => {
    return (
      <div>
         <h4>{label}</h4>
         <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width:500 }}>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="borderInp"
          />
          <button onClick={() => addSpeechIntentText(input, setInput, category, max)} className="btnYellow btnCustom modalButton">Add</button>
        </div>
        {sessionData.textCategories[category].map((cls: string, idx: number) => (
        <div key={idx} style={{ display: 'flex', alignItems: 'center', gap: '8px',marginBottom:10,marginTop:10 }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{idx + 1}. {cls}</p>
          <button 
            onClick={() => handleRemoveSpeechIntentClass(idx, category)}
            style={{ borderRadius:5, padding: '4px 8px', backgroundColor: 'red', color: 'white', border: 'none', cursor: 'pointer' }}>
            delete
          </button>
        </div>
      ))}
      </div>
    )
  }

  const addTtsText = () => {
    let cleanedString = ttsInput.toUpperCase().replace(/[^a-zA-Z\s]/g, "");
    if (cleanedString !== ""){
      if (sessionData.textClasses.includes(cleanedString)){
        toast.error("Class already exists")
      }else if (sessionData.textClasses.length >= 62){
        toast.error("Can not have more than 62 classes")
      }else {
        setSessionData({...sessionData, textClasses:[...sessionData.textClasses, cleanedString]})
        setTtsInput("");
      }
    }
  }

  const addSpeechIntentText = (input:string, setInput: any, category: string, max: number) => {
    let cleanedString = input.toUpperCase().replace(/[^a-zA-Z\s]/g, "");
    if (cleanedString !== ""){
      if (sessionData.textClasses.includes(cleanedString)){
        toast.error("Class already exists")
      }else if (sessionData.textCategories[category].length >= max){
        toast.error("Exceeded max amount")
      }else if (sessionData.textClasses.length >= 62){
        toast.error("Can not have more than 62 classes")
      }else {
        setSessionData(
        {
          ...sessionData, 
          textCategories:{...sessionData.textCategories, 
            [category]: [...sessionData.textCategories[category], cleanedString]},
          textClasses:[...sessionData.textClasses, cleanedString]
        })
        
        setInput("");
      }
    }
  
  }



  const sessionSetupInitializeDT = () => {
    const columns = [
      {
        name: "",
        button: true,
        cell: (row: any) => (
          <img
            onClick={(e) => {
              getWeights(row.name);
              //@ts-ignore
              setWeight({ folder: row.name });
            }}
            src={folder}
            className="tblZipIcon"
            alt="icon"
          />
        ),
      },
      {
        name: "Name",
        selector: (row: any) => row.name,
      },
      {
        name: "Last Modified",
        selector: (row: any) => row.modified,
      },
    ];
    return (
      <DataTable
        columns={columns}
        data={dataOptions.WEIGHTS}
        customStyles={DTStyles}
      />
    );
  };

  const sessionSetupInitializeListDT = (path: string) => {
    const columns = [
      {
        name: "Name",
        selector: (row: any) => row.name,
      },
      {
        name: "Last Modified",
        selector: (row: any) => row.modified,
      },
    ];
    return (
      <div>
        <button
          onClick={() => {
            setWeights([]);
            setWeight({ folder: "", file: "" });
          }}
          className="btnRed btnCustom btnSmall"
        >
          Clear
        </button>
        <h5 style={{ marginBottom: 3, marginTop: 7 }}>{weight.folder}</h5>
        <p style={{ marginTop: 0 }}>Select up to 1 bin files</p>
        <DataTable
          columns={columns}
          data={weights}
          customStyles={DTStyles}
          selectableRows={true}
          selectableRowsSingle
          selectableRowsHighlight
          onSelectedRowsChange={({ selectedRows }) => {
            if (selectedRows.length > 0) {
              setWeight({ ...weight, file: selectedRows[0].name });
            } else {
              setWeight({ ...weight, file: "" });
            }
          }}
        />
      </div>
    );
  };

  const wizardNavigationTabState = (tab: string) => {
    const { modelType, textClasses, rawDataPath, noiseDataPath, hitRateDataPath, falseAlarmDataPath,ambientNoiseDataPaths, impulsiveNoiseDataPaths } =
      sessionData;
    const condition1 = rawDataPath && noiseDataPath;
    const condition2 = ambientNoiseDataPaths && impulsiveNoiseDataPaths &&  (ambientNoiseDataPaths.length > 0 || impulsiveNoiseDataPaths.length > 0);
    const condition3 = (modelType == "Type2Speech" || modelType == "SpeechIntent") && textClasses.length > 0 && noiseDataPath;
    if (tab === "DAK" && (condition1 || condition2 || condition3)) {
      return WizardNavigationTabStates.completed;
    } else if (tab === "TSDK" && activeIndex > 1) {
      return WizardNavigationTabStates.completed;
    } else if (
      (tab === "ISDK" &&
        sessionData.hitRateDataPath &&
        sessionData.falseAlarmDataPath) ||
      !sessionData.runIsdk
    ) {
      return WizardNavigationTabStates.completed;
    }
    return WizardNavigationTabStates.default;
  };

  const items = [
    {
      state: wizardNavigationTabState("DAK"),
      title: "<b>Augment</b>",
      jump: false,
    },
    {
      state: wizardNavigationTabState("TSDK"),
      title: "<b>Train</b>",
      jump: false,
    },

    ...(sessionData.mode !== "Denoiser" ? [{
      state: wizardNavigationTabState("ISDK"),
      title: "<b>Test</b>",
      jump: false,
    }] : []),

    {
      state: WizardNavigationTabStates.default,
      title: "<b>Review</b> <br />",
      jump: false,
    },
  ];


  const getFourthStepNormalView = () => {
    return (
      <div className="wnWrapper">
        <div className="wnHead">
          <h2 className="wnHeading">Review Session</h2>
        </div>
        <div className="wnBody">
          <div className="sessionRevHolder">
            <div className="sessionRevWrap">
              <div className="sessionRevSec">
                <div className="revLeft">
                  <img
                    src={checkedGreen}
                    alt="checked"
                    className="checkedCircle"
                  />
                  <div>
                    <h3 className="wnSUbHead">Augment</h3>
                    <p className="wnDesc">
                      Input :<br />
                      {sessionData.mode != "Sensors" && (
                        <span>
                          Noise :<br />
                        </span>
                      )}
                      Configuration :<br />
                    </p>
                  </div>
                </div>
                <div className="revRight">
                  <p className="wnDesc weight300">
                    {sessionData.rawDataPath}
                    {sessionData.mode != "Sensors" && <br />}
                    {sessionData.noiseDataPath}
                  </p>
                  <p className="wnJason">
                    {JSON.stringify(sessionData.dakConfig)}
                  </p>
                </div>
              </div>
              <div className="sessionRevSec">
                <div className="revLeft">
                  <img
                    src={checkedGreen}
                    alt="checked"
                    className="checkedCircle"
                  />
                  <div>
                    <h3 className="wnSUbHead">Train</h3>
                    <p className="wnDesc">
                      Configuration:
                      <br />
                    </p>
                  </div>
                </div>
                <div className="revRight">
                  <p className="wnJason">
                    {JSON.stringify(sessionData.tsdkConfig)}
                  </p>
                </div>
              </div>
              {sessionData.mode !== 'Denoiser' && sessionData.runIsdk && (
                <div className="sessionRevSec">
                  <div className="revLeft">
                    <img
                      src={checkedGreen}
                      alt="checked"
                      className="checkedCircle"
                    />
                    <div>
                      <h3 className="wnSUbHead">Test</h3>
                      <p className="wnDesc">
                        Hit Rate :<br />
                        False Alarm :<br />
                        Configuration:
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="revRight">
                    <p className="wnDesc weight300">
                      {sessionData.hitRateDataPath} <br />
                      {sessionData.falseAlarmDataPath}
                    </p>
                    <p className="wnJason">
                      {JSON.stringify(sessionData.isdkConfig)}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="flex-justify-end">
              <div className="flex-align-center">
                <div className="btnHolder flex-align-center">
                  <a
                    onClick={() => setActiveIndex(sessionData.mode === "Denoiser" ? 1 : 2)}
                    className="btnCustom btnBordered"
                  >
                    Back
                  </a>
                  <a onClick={fetchClassMetaData} className="btnCustom btnGreen">
                    {" "}
                    <img src={playIcon} alt="play" />
                    Start
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getFirstStepTabView = () => {
    let headers = [];
    let body = [];

    if (sessionData.mode == "Sensors"){
      headers = [{ title: "Input Data" }];
      body = [<div>{sessionSetupTableDT(dataOptions.INPUT, "rawDataPath")}</div>];
    }else if (sessionData.mode == "Denoiser"){
      headers = [{ title: "Input Data" },{ title: "Ambient Noise Data" }, { title: "Impulsive Noise Data" }]
      body = [
        <div>{sessionSetupTableDT(dataOptions.INPUT, "rawDataPath")}</div>,
        <div>{sessionSetupTableDT(dataOptions.NOISE_AMBIENT, "ambientNoiseDataPaths", false)}</div>,
        <div>{sessionSetupTableDT(dataOptions.NOISE_IMPULSIVE, "impulsiveNoiseDataPaths", false)}</div>,
        ];
    }else{
      headers = [{ title: "Input Data" }, { title: "Noise Data" }];
      body = [
        <div>{sessionSetupTableDT(dataOptions.INPUT, "rawDataPath")}</div>,
        <div>{sessionSetupTableDT(dataOptions.NOISE, "noiseDataPath")}</div>
      ]
    }

    headers.push({title: 'Configuration'});
    body.push(
      <div>
        <h4>Version :</h4>
        <select onChange={(e) => {setSessionData({ ...sessionData, dakDataPath: e.target.value });}} className="customSelect m-l-10" name="sessionName">
          {dataOptions.DAK.map((dak: { name: string }) => (
            <option key={dak.name} value={dak.name} selected={dak.name === sessionData.dakDataPath}> {dak.name} </option>))}
        </select>
        {
          (["Type2Speech","MinWav2Speech","SpeechIntent"].includes(sessionData.modelType))
            &&
          <div>
            <h4>Augmentation Type : </h4>
            <div style={{ display: 'flex', marginBottom: 20}}>
              <button
                onClick={() => setSessionData({...sessionData, maxAug: false})}
                style={{padding: '10px',borderTopLeftRadius:10,borderBottomLeftRadius:10, backgroundColor: !sessionData.maxAug ? 'black' : '#cfcece',color: 'white',border: 'none'
                }}
              >
                Quick Aug
              </button>
              <button
                onClick={() => setSessionData({...sessionData, maxAug: true})}
                style={{padding: '10px',borderTopRightRadius:10,borderBottomRightRadius:10,backgroundColor: sessionData.maxAug ? 'black' : '#cfcece',color: 'white',border: 'none'
                }}
              >
                Max Aug
              </button>
            </div>
          </div>
        }
        <h4>Avanced Options : </h4>
        <label>Noise Cleaning</label>
        <select onChange={(e) => {setSessionData({ ...sessionData, dakConfig: { ...sessionData.dakConfig, NC: e.target.value}});}} className="customSelect m-l-10" name="sessionName">
          {["0","1"].map((value) => (
            <option key={value} value={value} selected={value === sessionData.dakConfig.NC}> {value} </option>))}
        </select>
        <label style={{marginLeft:5}}>Mode</label>
        <select onChange={(e) => {setSessionData({ ...sessionData, dakConfig: { ...sessionData.dakConfig, Mode: e.target.value}});}} className="customSelect m-l-10" name="sessionName">
          {["NF","MF","FF"].map((value) => (
            <option key={value} value={value} selected={value === sessionData.dakConfig.Mode}> {value} </option>))}
        </select>
        <label style={{marginLeft:5}}>VAD</label>
        <select onChange={(e) => {setSessionData({ ...sessionData, dakConfig: { ...sessionData.dakConfig, VAD: e.target.value}});}} className="customSelect m-l-10" name="sessionName">
          {["0","1"].map((value) => (
            <option key={value} value={value} selected={value === sessionData.dakConfig.VAD}> {value} </option>))}
        </select>
        <label style={{marginLeft:5, marginRight:5}}>Split Ratio</label>
        <input type="text" value={sessionData.dakConfig.split_ratio} onChange={(e) => {setSessionData({ ...sessionData, dakConfig: { ...sessionData.dakConfig, split_ratio: e.target.value}});}} className="borderInpSm"/>
      </div>
    );

    let handleNext = null;

    const isModeAudioOrSID = sessionData.mode === "Audio" || sessionData.mode === "SID";
    const isModeSensors = sessionData.mode === "Sensors";
    const isModeDenoiser = sessionData.mode === "Denoiser";

    if (
        (isModeAudioOrSID && sessionData.rawDataPath && sessionData.noiseDataPath) ||
        (isModeAudioOrSID && ["Type2Speech","SpeechIntent"].includes(sessionData.modelType) && (sessionData.textClasses.length > 0) && sessionData.noiseDataPath) || 
        (isModeSensors && sessionData.rawDataPath) ||
        (isModeDenoiser && 
          sessionData.rawDataPath && 
            (
              sessionData.ambientNoiseDataPaths.length > 0 || 
              sessionData.impulsiveNoiseDataPaths.length > 0
            )
    
        )
    ) {
        handleNext = () => {
            setActiveIndex(1);
        };
    }

    return (
      <TabView
        title="Step 1: Augment"
        header={headers}
        body={body}
        onNext={handleNext}
      />
    );
  };

  const getSecondStepTabView = () => {
    const headers = [
      {
        title: "Configuration",
      },
      ...( ["Type2Speech","MinWav2Speech","SpeechIntent"].includes(sessionData.modelType) ? [] : [{title: "Initialize Weights (optional)"}]),
      // {
      //   title:'Version'
      // }
    ];

    const body = [
      <div>
        <h4>Version : </h4>
        <select onChange={(e) => {setSessionData({ ...sessionData, tsdkDataPath: e.target.value });}} className="customSelect m-l-10" name="sessionName">
          {dataOptions.TSDK.map((tsdk: { name: string }) => (
            <option key={tsdk.name} value={tsdk.name} selected={tsdk.name === sessionData.tsdkDataPath}> {tsdk.name} </option>))}
        </select>
        <h4>Avanced Options : </h4>
        <label style={{marginLeft:5, marginRight:5}}>Epochs</label>
        <input type="text" value={sessionData.tsdkConfig.epochs} onChange={(e) => {setSessionData({ ...sessionData, tsdkConfig: { ...sessionData.tsdkConfig, epochs: e.target.value}});}} className="borderInpSm"/>
        <label style={{marginLeft:5, marginRight:5}}>Dropout</label>
        <input type="text" value={sessionData.tsdkConfig.dropout} onChange={(e) => {setSessionData({ ...sessionData, tsdkConfig: { ...sessionData.tsdkConfig, dropout: e.target.value}});}} className="borderInpSm"/>
        <label style={{marginLeft:5, marginRight:5}}>Batch Size</label>
        <input type="text" value={sessionData.tsdkConfig.batch_size} onChange={(e) => {setSessionData({ ...sessionData, tsdkConfig: { ...sessionData.tsdkConfig, batch_size: e.target.value}});}} className="borderInpSm"/>
        <label style={{marginLeft:5, marginRight:5}}>Learning Rate</label>
        <input type="text" value={sessionData.tsdkConfig.lr} onChange={(e) => {setSessionData({ ...sessionData, tsdkConfig: { ...sessionData.tsdkConfig, lr: e.target.value}});}} className="borderInpSm"/>
        <label style={{marginLeft:5, marginRight:5}}>L2 Regularization</label>
        <input type="text" value={sessionData.tsdkConfig.lr} onChange={(e) => {setSessionData({ ...sessionData, tsdkConfig: { ...sessionData.tsdkConfig, lr: e.target.value}});}} className="borderInpSm"/>
        <label style={{marginLeft:5, marginRight:5}}>Patience</label>
        <input type="text" value={sessionData.tsdkConfig.patience} onChange={(e) => {setSessionData({ ...sessionData, tsdkConfig: { ...sessionData.tsdkConfig, patience: e.target.value}});}} className="borderInpSm"/>
        <label style={{marginLeft:5, marginRight:5}}>Seed 1</label>
        <input type="text" value={sessionData.tsdkConfig.training_seed1} onChange={(e) => {setSessionData({ ...sessionData, tsdkConfig: { ...sessionData.tsdkConfig, training_seed1: e.target.value}});}} className="borderInpSm"/>
        <label style={{marginLeft:5, marginRight:5}}>Seed 2</label>
        <input type="text" value={sessionData.tsdkConfig.training_seed2} onChange={(e) => {setSessionData({ ...sessionData, tsdkConfig: { ...sessionData.tsdkConfig, training_seed2: e.target.value}});}} className="borderInpSm"/>
        <label style={{marginLeft:5, marginRight:5}}>Seed 3</label>
        <input type="text" value={sessionData.tsdkConfig.training_seed3} onChange={(e) => {setSessionData({ ...sessionData, tsdkConfig: { ...sessionData.tsdkConfig, training_seed3: e.target.value}});}} className="borderInpSm"/>
      </div>,

      <div>
        {weights.length > 1
          ? sessionSetupInitializeListDT("weight")
          : sessionSetupInitializeDT()}
      </div>,
      // <div>{sessionSetupTableDT(dataOptions.TSDK,'tsdkDataPath')}</div>,
    ];
    return (
      <TabView
        title="Step 2: Train"
        header={headers}
        body={body}
        onNext={() => {
          setActiveIndex(2);
        }}
        onBack={() => {
          setActiveIndex(0);
        }}
      />
    );
  };

  const getThirdStepTabView = () => {

      let headers = [{title: "Hit Rate Data"}, {title: "False Alarm Data"}, {title: 'Configuration'}];
      let body = [
        <div>
          {sessionSetupTableDT(dataOptions.HIT_RATE, "hitRateDataPath")}
          <p>
            Would you like to skip test?{" "}
            <input
              type="checkbox"
              checked={!sessionData.runIsdk}
              onChange={(e) =>
                setSessionData({ ...sessionData, runIsdk: !e.target.checked })
              }
            />
          </p>
        </div>,
        <div>
          {sessionSetupTableDT(dataOptions.FALSE_ALARM, "falseAlarmDataPath")}
          <p>
            Would you like to skip test?{" "}
            <input
              type="checkbox"
              checked={!sessionData.runIsdk}
              onChange={(e) =>
                setSessionData({ ...sessionData, runIsdk: !e.target.checked })
              }
            />
          </p>
        </div>,
        <div>
        <h4>Version : </h4>
        <select onChange={(e) => {setSessionData({ ...sessionData, isdkDataPath: e.target.value });}} className="customSelect m-l-10" name="sessionName">
          {dataOptions.ISDK.map((isdk: { name: string }) => (
            <option key={isdk.name} value={isdk.name} selected={isdk.name === sessionData.isdkDataPath}> {isdk.name} </option>))}
        </select>
        <h4>Avanced Options : </h4>
        <label>DE</label>
        <select onChange={(e) => {setSessionData({ ...sessionData, isdkConfig: { ...sessionData.isdkConfig, DE: e.target.value}});}} className="customSelect m-l-10" name="sessionName">
          {["0","1"].map((value) => (
            <option key={value} value={value} selected={value === sessionData.isdkConfig.DE}> {value} </option>))}
        </select>
        <label style={{marginLeft:5}}>HR Suppress</label>
        <select onChange={(e) => {setSessionData({ ...sessionData, isdkConfig: { ...sessionData.isdkConfig, HR_SUPPRESS: e.target.value}});}} className="customSelect m-l-10" name="sessionName">
          {["0","1"].map((value) => (
            <option key={value} value={value} selected={value === sessionData.isdkConfig.HR_SUPPRESS}> {value} </option>))}
        </select>
        <label style={{marginLeft:5}}>Detection Suppress</label>
        <select onChange={(e) => {setSessionData({ ...sessionData, isdkConfig: { ...sessionData.isdkConfig, DETECTION_SUPPRESS: e.target.value}});}} className="customSelect m-l-10" name="sessionName">
          {["0","1"].map((value) => (
            <option key={value} value={value} selected={value === sessionData.isdkConfig.DETECTION_SUPPRESS}> {value} </option>))}
        </select>
        <label style={{marginLeft:5}}>ROC Suppress</label>
        <select onChange={(e) => {setSessionData({ ...sessionData, isdkConfig: { ...sessionData.isdkConfig, ROC_SUPPRESS: e.target.value}});}} className="customSelect m-l-10" name="sessionName">
          {["0","1"].map((value) => (
            <option key={value} value={value} selected={value === sessionData.isdkConfig.ROC_SUPPRESS}> {value} </option>))}
        </select>
        <p>
          Would you like to skip test?{" "}
          <input
            type="checkbox"
            checked={!sessionData.runIsdk}
            onChange={(e) =>
              setSessionData({ ...sessionData, runIsdk: !e.target.checked })
            }
          />
        </p>
      </div>,
      ]

    


    let handleNext = null;

    const isModeAudioOrSID = sessionData.mode === "Audio" || sessionData.mode === "SID";
    const isModeSensors = sessionData.mode === "Sensors";
    const isModeDenoiser = sessionData.mode === "Denoiser";
    
    if (
        ((isModeAudioOrSID || isModeSensors) && sessionData.hitRateDataPath && sessionData.falseAlarmDataPath) ||
        (!sessionData.runIsdk)
    ) {
        handleNext = () => {
            setActiveIndex(3);
        };
    }

    return (
      <TabView
        title="Step 3: Test"
        header={headers}
        body={body}
        onNext={handleNext}
        onBack={() => {
          setActiveIndex(1);
        }}
      />
    );
  };

  const modelSelection = () => {
    return (
      <div className="globalCard">
        <p style={{ textAlign: "center", fontWeight: 600 }}>
          Select which type of Audio Model you would like to run.
        </p>
        <div className="customColumn-12 text-center">
          <button
            onClick={() => features.wav2model && setSessionData({ ...sessionData, modelType: "Wav2Speech" })}
            className={features.wav2model ? "btnBlack btnCustom modalButton" : "btnGrey btnCustom modalButton"}
          >
            Wav2Speech Model
          </button>
          <button
            onClick={() => features.type2model && setSessionData({ ...sessionData, modelType: "Type2Speech", tts:true })}
            className={features.type2model ? "btnBlack btnCustom modalButton" : "btnGrey btnCustom modalButton"}
          >
            Type2Speech Model
          </button>
          <button
            onClick={() => features.minwav2model && setSessionData({ ...sessionData, modelType: "MinWav2Speech" })}
            className={features.minwav2model ? "btnBlack btnCustom modalButton" : "btnGrey btnCustom modalButton"}
          >
            MinWav2Speech Model
          </button>
        </div>
      </div>
    )
  }

  const firstStepBody = {
    type: TWizardBodyType.tab,
    content: getFirstStepTabView(),
  };

  const secondStepBody = {
    type: TWizardBodyType.tab,
    content: getSecondStepTabView(),
  };

  const thirdStepBody = {
    type: TWizardBodyType.tab,
    content: getThirdStepTabView(),
  };

  const fourthStepBody = {
    type: TWizardBodyType.normal,
    content: getFourthStepNormalView(),
  };

  const totalBody = [
    firstStepBody,
    secondStepBody,
    ...(sessionData.mode !== 'Denoiser') ? [thirdStepBody] : [],
    fourthStepBody,
  ];

  if (sessionId) {
    return (
      <div>
        <div className="sectionHead">
          <div className="flex-align-center">
            <h2 className="sectionHeading">Session : </h2>
            <select
              onChange={(e) => {
                setSessionId(e.target.value);
                navigate(`/admin/session/setup?id=${e.target.value}`);
              }}
              className="customSelect m-l-10"
              name="sessionName"
              id=""
            >
              {setupSessions.map((session: { _id: string; name: string }) => (
                <option
                  key={session._id}
                  value={session._id}
                  selected={session._id === sessionId}
                >
                  {session.name}
                </option>
              ))}
            </select>
          </div>
          {sessionData.status === 0 && (
            <div className="btnHolder flex-align-center">
              {/*
                            <a onClick={saveSession} className="btnCustom btnBordered">
                                <img src={saveIcon} alt="save" />
                                Save
                            </a>

                            */}
              {sessionData.status !== 1 && (
                <a
                  onClick={() => setDeleteModal(true)}
                  className="btnCustom btnRed"
                >
                  <img src={deleteIconWhite} alt="delete" />
                  Delete
                </a>
              )}
            </div>
          )}
        </div>
        {!loading ? (
          sessionData.status === 0 ? (
            <>
              {
              sessionData.modelType
                ? 
                  dataOptions.DAK.length > 0
                    ?<Wizard
                    header={items}
                    body={totalBody}
                    activeIndex={activeIndex}
                  />
                  :<div className="loading">
                    <div className="loading-spinner"></div>
                  </div>
                
                :modelSelection()}
              {deleteModal && (
                <DeleteModal
                  title="Session"
                  subtitle="Are you sure you want to delete this session? Doing this will permenatly destroy your progress."
                  onCancel={() => setDeleteModal(false)}
                  onDelete={deleteSession}
                />
              )}
              {epochWarning && (
                <DeleteModal
                  title="Epoch Warning"
                  subtitle={epochWarning}
                  onCancel={() => setEpochWarning("")}
                  onDelete={() => recommendedSplitRatio(metaData)}
                  base={true}
                  action={"Run Anyways"}
                />
              )}
              {splitWarning && (
                <DeleteModal
                  title="Split Ratio Warning"
                  subtitle={splitWarning}
                  onCancel={() => setSplitWarning("")}
                  onDelete={startSession}
                  base={true}
                  action={"Run Anyways"}
                />
              )}
            </>
          ) : (
            <LaunchingModal
              title={"Session Launching"}
              subtitle={
                "Your session is currently launching, this will take a few minutes."
              }
            />
          )
        ) : (
          <div className="loading">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>
    );
  } else if (!loading) {
    return (
      <div className="no-items">
        <h2>No Sessions in Setup</h2>
        <p>Sessions that are in setup will appear here.</p>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Setup;

