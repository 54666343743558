import React from "react";
import { TLoginProps } from "./Login.types";
import { useState, useRef, useContext, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import loginPageImage from "../../../assets/images/login-page-img.jpg"
import authLogoImage from "../../../assets/images/auth-logo.svg"
import hidePasswordImage from "../../../assets/images/hide.svg"
import showPasswordImage from "../../../assets/images/show.svg"
import AuthContext from "../../../store/auth-context";

import authApi from "../../../services/authentication";
import useApi from "../../../services/Base/useApi";

const Login = (props: TLoginProps) => {


    const loginFormRef = useRef() as React.MutableRefObject<HTMLFormElement>;
    const emailInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const passwordInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const customerIdInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
    let navigate = useNavigate();



    const authCtx = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    const loginApi = useApi(authApi.postLogin)

    useEffect(()=>{
      if(loginApi.loading === false){
        if(loginApi.error){
            toast.error(loginApi.error)
            loginApi.clearError()
        }
        else if(loginApi.data){
            console.log(loginApi.data['features'])
          let expirationTime = new Date();
          expirationTime.setDate(expirationTime.getDate() + 2);
          authCtx.login(loginApi.data['token'], loginApi.data['companyId'],expirationTime.toISOString(),loginApi.data['user']['name'],
              "https://aondevices.com/wp-content/uploads/2021/05/ARRUNA.png", loginApi.data['created'], loginApi.data['evalPeriod'], JSON.stringify(loginApi.data['features']));
          window.location.replace('/admin');
        //   navigate('/admin',{ replace: true });
      }
      }
    },[loginApi.loading,loginApi.error,loginApi.data])

    const submitHandler = async (event:React.FormEvent) => {
        event.preventDefault();


        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        const enteredCustomerID = customerIdInputRef.current.value;

        // optional: Add validation and call API to perform login
        if(!enteredEmail || !enteredPassword || !enteredCustomerID) return;
        const data = { username: enteredEmail, password: enteredPassword, companyId: enteredCustomerID };
        loginApi.request(data);
        // process response
    };



    return (
        <div className="authWrapper">
        <div>

            <div className="authPageWrap">
                <div className="authPageWrap">
                    <div className="authFormLeft">
                        <div className="authFormWrapper">
                            <img src={authLogoImage} alt="logo"  className="authLogo"/>
                            <form ref={loginFormRef} onSubmit={submitHandler}>
                            <div className="customRow">
                                <div className="customColumn-12  m-b-20">
                                    <label className="formLabel font-bold">Company ID</label>
                                    <div className="gap10"></div>
                                    <input type="text"  ref={customerIdInputRef} name={'customerId'} id={'customerId'} className="inputText authInput" placeholder="776626829"/>
                                </div>
                                <div className="customColumn-12  m-b-20">
                                    <label  className="formLabel font-bold">Username</label>
                                    <div className="gap10"></div>
                                    <input type="text" ref={emailInputRef}  name={'emailAddress'} id={'emailAddress'} className="inputText authInput" placeholder="name@example.com"/>
                                </div>
                                <div className="customColumn-12  m-b-20 ">
                                    <label  className="formLabel font-bold">Password</label>
                                    <div className="gap10"></div>
                                    <div className="passwordDiv">
                                        <input type="password"  ref={passwordInputRef} name={'password'} id={'password'} className="inputText authInput" placeholder="password"/>

                                    </div>
                                </div>
                                <div className="customColumn-12  m-b-20 text-center">
                                    <button type={"submit"} className="btnCustom btnYellowGradient authButton">Log In</button>
                                </div>
                                <div className="customRow m-b-20">
                                    <div className="customColumn-12 authTerms">
                                          <label htmlFor="termsAcpt"> By clicking Log In, you agree with AONCloud ‘s <a href="/terms-and-conditions">Terms,</a><a href="/privacy-policy"> Privacy Policy</a></label>
                                    </div>
                                </div>
                                <div className="customRow">
                                    <div className="customColumn-12 text-center">
                                        <Link to="/forgot-password" className="forgetLabel">Forgot password</Link>
                                    </div>
                                </div>
                                <div className="customColumn-12  m-b-20 text-center">
                                    <Link to="/register" className="btnCustom btnBorderedYellow authButtonBorder">
                                        Sign up now
                                    </Link>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    <div className="authImgRight">
                        <div className="authImageWrap">
                            <img src={loginPageImage} alt="login image"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </div>
    )
}

export default Login;
